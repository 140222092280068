var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%", padding: "20px" } },
          [
            _c("div", {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: [""],
                  expression: "['']",
                },
              ],
            }),
            _vm._v(" "),
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { margin: "0px 0px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c("el-tree", {
                    attrs: {
                      data: _vm.logData,
                      "node-key": "id",
                      "expand-on-click-node": false,
                      props: _vm.defaultProps,
                      "render-content": _vm.renderContent,
                    },
                  }),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "文件预览",
                  visible: _vm.dialogVisible,
                  width: "80%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                  close: _vm.cancel,
                },
              },
              [
                _c(
                  "el-card",
                  { staticClass: "box-card" },
                  _vm._l(_vm.files, function (item, index) {
                    return _c("div", { key: index, staticClass: "text item" }, [
                      _c("span", [
                        _vm._v(_vm._s(100 * (_vm.page - 1) + index + 1) + ". "),
                      ]),
                      _c("p", [_vm._v(_vm._s(item))]),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.pageShow
                  ? _c(
                      "el-col",
                      {
                        staticClass: "pages",
                        staticStyle: { margin: "10px 0px" },
                        attrs: { span: 24 },
                      },
                      [
                        _c("el-pagination", {
                          staticClass: "pagination",
                          attrs: {
                            "current-page": _vm.currentPage,
                            "page-size": 100,
                            layout: "total, prev, pager, next",
                            total: _vm.total,
                          },
                          on: {
                            "current-change": _vm.handleCurrentChange,
                            "update:currentPage": function ($event) {
                              _vm.currentPage = $event
                            },
                            "update:current-page": function ($event) {
                              _vm.currentPage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "skhmnd boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }