import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import axios from 'axios';
import { getTokens } from '@/utils/auth';
import { businessLog as _businessLog, logView } from '@/api/updateUserInfo/Journal';
import Axios from 'axios';
export default {
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '业务日志查询',
      logData: [],
      defaultProps: {
        label: 'path'
      },
      id: '',
      dialogVisible: false,
      files: [],
      pageShow: false,
      page: 1,
      total: 0,
      currentPage: 1,
      path: '',
      downloadShow: false,
      viewShow: false
    };
  },
  components: {
    Head: Head
  },
  mounted: function mounted() {
    if (this.course == 1) {} else {
      this.businessLog();
      if (this.$store.getters.Atter.includes("日志目录/文件下载")) {
        this.downloadShow = true;
      }
      if (this.$store.getters.Atter.includes("日志文件预览")) {
        this.viewShow = true;
      }
    }
  },
  methods: {
    businessLog: function businessLog(val) {
      var _this = this;
      _businessLog().then(function (res) {
        if (res.data !== '') {
          _this.logData = res.data;
        }
      });
    },
    renderContent: function renderContent(h, _ref) {
      var _this2 = this;
      var node = _ref.node,
        data = _ref.data,
        store = _ref.store;
      if (data.children) {
        return h("span", {
          "class": "custom-tree-node"
        }, [h("span", [node.label]), h("span", {
          "class": "handle"
        }, [this.downloadShow ? h("el-button", {
          "attrs": {
            "size": "mini",
            "type": "text"
          },
          "on": {
            "click": function click() {
              return _this2.download(data);
            }
          }
        }, ["\u4E0B\u8F7D"]) : ''])]);
      } else {
        return h("span", {
          "class": "custom-tree-node"
        }, [h("span", [node.label]), h("span", {
          "class": "handle"
        }, [this.downloadShow ? h("el-button", {
          "attrs": {
            "size": "mini",
            "type": "text"
          },
          "on": {
            "click": function click() {
              return _this2.download(data);
            }
          }
        }, ["\u4E0B\u8F7D"]) : '', this.viewShow ? h("el-button", {
          "directives": [{
            name: "if",
            value: "viewShow"
          }],
          "attrs": {
            "size": "mini",
            "type": "text"
          },
          "on": {
            "click": function click() {
              return _this2.view(data);
            }
          }
        }, ["\u9884\u89C8"]) : ''])]);
      }
    },
    //下载方法
    downloadExcel: function downloadExcel(res) {
      var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '未命名';
      var a = document.createElement('a');
      var blob = new Blob([res.data], {
        type: 'application/zip'
      });
      var url = URL.createObjectURL(blob);
      a.setAttribute('href', url);
      a.setAttribute('download', fileName);
      a.click();
    },
    download: function download(e) {
      var _this3 = this;
      axios.defaults.headers.common['token'] = getTokens();
      axios.defaults.headers.common['content-type'] = 'application/octet-stream,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      Axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_API2 + '/clientserver.php/BackstageOperation/log_dir_download',
        responseType: 'arraybuffer',
        data: {
          path: e.path
        }
      }).then(function (res) {
        _this3.downloadExcel(res, e.path);
        location.reload();
      });
    },
    view: function view(e) {
      var _this4 = this;
      this.page = 1;
      this.currentPage = 1;
      this.dialogVisible = true;
      this.path = e.path;
      logView({
        path: e.path,
        page: 1,
        pagesize: 100
      }).then(function (res) {
        _this4.total = res.data.total;
        if (res.data.total > 100) {
          _this4.pageShow = true;
        }
        _this4.files = res.data.data;
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;
      this.page = val;
      logView({
        path: this.path,
        page: val,
        pagesize: 100
      }).then(function (res) {
        _this5.total = res.data.total;
        if (res.data.total > 100) {
          _this5.pageShow = true;
        }
        _this5.files = res.data.data;
      });
    },
    cancel: function cancel() {}
  }
};